<template>
  <router-view
    @show-premium-feature-popup="showPremiumFeaturePopup"
  />
</template>
<script>
import {userStore} from '@/store/__STORE_user';
export default {
  created() {
    userStore.getUserProfile();
    userStore.getUserSettings();
  },
  methods: {
    showPremiumFeaturePopup(premiumPopupTexts) {
      this.$emit('show-premium-feature-popup', premiumPopupTexts);
    },
  },
};
</script>
